<template>
  <v-app>
    <div v-if="dataJson">
      <div class="row">
        <div class="col-xl-12">
          <v-card
            class="mx-auto rounded-lg ecosistema-ve"
            elevation="10"
            height="100%"
            outlined
          >
            <v-card-title>
              <v-avatar size="56">
                <img
                  alt="Vehículo Eléctrico"
                  src="/media/cide/ecosistemas/VE.png"
                />
              </v-avatar>
              <h1 class="ml-3 texto-ecosistema-ve">Vehículo Eléctrico</h1>
            </v-card-title>
            <v-card-text>
              <div class="row">
                <div class="col-xl-8" />
                <div class="col-xl-2">No rural</div>
                <div class="col-xl-2">Rural</div>
              </div>

              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Nº vehículos eléctricos registrados en zonas rurales 2020
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato">{{
                    dataJson[2020].nacional
                  }}</span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].rural }}
                    <!--{{ dataJson.rural.cve }}-->
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Nº vehículos eléctricos registrados en zonas rurales 2021
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].nacional }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].rural }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Nº vehículos eléctricos registrados en zonas rurales 2022
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].nacional }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].rural }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>Penetración del vehículo eléctrico </v-card-title>
            <v-card-subtitle>
              (número de vehículos eléctricos)
            </v-card-subtitle>
            <v-card-subtitle>
              haz click para saber más información sobre una CA en específico
            </v-card-subtitle>

            <v-card-text>
              <fusioncharts
                type="spain"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="dsMapaINP01"
              />
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>
              Contribución a la descarbonización del Vehículo Eléctrico
            </v-card-title>
            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="dsNacionalINP01"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>Número de vehículos eléctricos</v-card-title>

            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="'/data/VE/nacional/tipovehiculo_inp04.json'"
              />
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Contribución a la descarbonización del Vehículo Eléctrico según el
              tipo de vehículo
            </v-card-title>
            <v-card-subtitle>
              (toneladas CO2 equivalente evitadas * número de vehículos)
            </v-card-subtitle>

            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="dsNacionalTipovehiculoINP01"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>Grado de implementación </v-card-title>
            <v-card-subtitle>
              (vehículos Eléctricos por provincia)
            </v-card-subtitle>
            <v-card-text>
              <fusioncharts
                type="spainprovinces"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="dsMapaprovincias"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <v-col cols="12" sm="6">
          <v-card
            class="mx-auto rounded-lg"
            elevation="10"
            outlined
            height="100%"
          >
            <v-card-title>
              Intención de adquisición de un vehículo eléctrico en el medio
              rural
            </v-card-title>

            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="400"
                data-format="jsonurl"
                :data-source="dsNacionalINP06"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              ¿Dónde acostumbra a cargar su vehículo?
            </v-card-title>

            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    color="#e44a00"
                    data-format="json"
                    :data-source="dsNacionalINP07[0]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <fusioncharts
                    type="doughnut2d"
                    width="100%"
                    height="500"
                    color="#e44a00"
                    data-format="json"
                    :data-source="dsNacionalINP07[1]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </div>

      <div class="row">
        <v-col cols="12" sm="6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Intención de instalación de infraestructura privada
            </v-card-title>

            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="400"
                data-format="jsonurl"
                :data-source="dsNacionalINP08"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      selectedVariable:
        "Contribución a la descarbonización del Vehículo Eléctrico",
      selectedYear: "2020",
      selectedOption: "Todos",
      chartColumn: {
        type: "column2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      chartMultipleColumn: {
        type: "mscolumn2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      mapSpain: {
        type: "spain",
        width: "100%",
        height: "300",
        dataFormat: "jsonurl",
      },
      dsMapaINP01: "./data/VE/nacional/mapa_inp01.json",
      dsNacionalINP01: "./data/VE/nacional/inp01.json",
      dsNacionalTipovehiculoINP01: "./data/VE/nacional/tipovehiculo_inp01.json",
      dsTipoVehiculoINP04: "./data/VE/nacional/tipovehiculo_inp04.json",
      dsHabitantesINP03: "./data/VE/nacional/habitantes_inp03.json",
      dsNacionalINP06: "./data/VE/nacional/inp06.json",
      dsNacionalINP07: null,
      dsNacionalINP08: "./data/VE/nacional/inp08.json",
      dsantes2INP06: "./data/VE/nacional/mapa_antes2_inp06.json",
      dsentre2y5INP06: "./data/VE/nacional/mapa_entre2y5_inp06.json",
      dsMapaprovincias: "./data/VE/nacional/provincias.json",
      dataJson: null,
    };
  },
  beforeMount() {
    this.getJsonData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Vehículo eléctrico" }]);
  },
  methods: {
    getJsonData() {
      fetch("/data/VE/nacional/data.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson = data;
        });

      fetch("/data/VE/nacional/inp07.json")
        .then((response) => response.json())
        .then((data) => {
          this.dsNacionalINP07 = data;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
